exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-deputy-compliance-jsx": () => import("./../../../src/pages/project/deputy-compliance.jsx" /* webpackChunkName: "component---src-pages-project-deputy-compliance-jsx" */),
  "component---src-pages-project-deputy-gamification-jsx": () => import("./../../../src/pages/project/deputy-gamification.jsx" /* webpackChunkName: "component---src-pages-project-deputy-gamification-jsx" */),
  "component---src-pages-project-deputy-growth-experiments-jsx": () => import("./../../../src/pages/project/deputy-growth-experiments.jsx" /* webpackChunkName: "component---src-pages-project-deputy-growth-experiments-jsx" */),
  "component---src-pages-project-deputy-product-tour-jsx": () => import("./../../../src/pages/project/deputy-product-tour.jsx" /* webpackChunkName: "component---src-pages-project-deputy-product-tour-jsx" */),
  "component---src-pages-project-safety-culture-onboarding-jsx": () => import("./../../../src/pages/project/safety-culture-onboarding.jsx" /* webpackChunkName: "component---src-pages-project-safety-culture-onboarding-jsx" */),
  "component---src-pages-project-tournament-app-jsx": () => import("./../../../src/pages/project/tournament-app.jsx" /* webpackChunkName: "component---src-pages-project-tournament-app-jsx" */)
}

